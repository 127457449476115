.tracking-in-contract {
  animation: tracking-in-contract 2s linear both;
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 0.6em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
